import React from "react";
import { graphql } from "gatsby";
import Standard from "src/templates/Standard";

export default function CaseStudy(props) {
	return <Standard {...props} />;
}

export const query = graphql`
	query CaseStudyPage($pageUri: String, $language: String) {
		entry: craftCaseStudyPageCaseStudyPageEntry(
			uri: { eq: $pageUri }
			language: { eq: $language }
		) {
			...CaseStudyPageEntry
		}
	}
`;
